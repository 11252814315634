import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
// import LogoIcon from '../../svg/LogoIcon';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <Link className="flex text-2xl" to="/">
        <div className="w-40">
          {/* <LogoIcon /> */}
          <img src={'../../../sg-logo.png'} alt="SG Innovations logo" />
        </div>
        {/* <div className="pt-12">SG Innovations </div> */}
      </Link>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#services">
          Services
        </AnchorLink>
        <AnchorLink className="px-4" href="#insights">
          Insights
        </AnchorLink>
        {/* <AnchorLink className="px-4" href="#testimonials">
          Testimonials
        </AnchorLink> */}
        <AnchorLink className="px-4" href="#about">
          About
        </AnchorLink>
        <Link className="px-4" to="/careers">
          Careers
        </Link>
        <Link className="px-4" to="/contact">
          Contact
        </Link>
      </div>
    </div>
  </header>
);

export default Header;
